import BreadcrumbCustum from "components/elements/breadcrumb/Breadcrumb";
import { useRouter } from "next/router";

const LandingPage = (props) => {
  const router = useRouter();

  router.push("/manset");
  const pages = [
    {
      path: "",
    },
    {
      breadcrumbName: "Kontrol Paneli",
    },
  ];

  return (
    <div>
      <BreadcrumbCustum list={pages} />
    </div>
  );
};

LandingPage.layout = "admin";
export default LandingPage;
