import styled from "styled-components";

var BreadcrumbStyle = styled.div`
    .page-title{
        font-weight: lighter;
        font-size: 1em;
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: 500;
        height: 20px !important;
        font-family: system-ui;
    }
    .anticon-home{
        position: relative;
        top: -3px;
        font-size: 0.9em!important;
    }
    padding: 10px 1.5rem;
    -webkit-box-shadow: 0 1px 2px rgba(56 ,65 ,74 , 0.15);
    box-shadow: 0 1px 2px rgba(56 ,65 ,74 , 0.15);
    border-bottom: 1px solid none;
    border-top: 1px solid none;
    background-color:white;
    margin: 0 0 30px 0;
    a{
        font-size: 0.9em!important;
    }
    @media (min-width:450px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export default BreadcrumbStyle;


