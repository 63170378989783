import React from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import Link from "next/link";
import BreadcrumbStyle from "./breadcrumb.style";

function BreadcrumbCustum(props) {
  const routes = props.list;
  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;

    const n = (
      <>
        <Link key={paths.join("/")} href={"/"+paths.join("/")}>{route.breadcrumbName}</Link>
      </>
    );

    if (route.path === "") {
      return (
        <>
          <HomeOutlined /> Kontrol Paneli
        </>
      );
    } else if (!route.path) {
      return <>{route.breadcrumbName}</>;
    } else {
      return n;
    }
  }

  return (
    <BreadcrumbStyle>
      <h4 className="page-title">{routes[routes.length - 1].breadcrumbName}</h4>
      <Breadcrumb separator="/" itemRender={itemRender} routes={routes} />
    </BreadcrumbStyle>
  );
}

export default BreadcrumbCustum;
